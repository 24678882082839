<template>
    <front-layout>
        <div class="flex-wrapper-full-background d-flex  fill-height" style="width: 100%">
            <v-card class="mx-auto my-auto pa-5" max-width="900" min-width="500" raised>
                <v-card-title>{{ $t('auth.password_reset') }}</v-card-title>
                <v-card-text>
                    <v-form ref="form">
                        <v-row>
                            <v-col>
                                <v-text-field :error-messages="errors.email" :label="$t('auth.email')" v-model="email"
                                              :rules="rules_email"
                                              type="email"
                                              @input="btn_active()"/>
                            </v-col>
                        </v-row>

                    </v-form>
                </v-card-text>
                <v-card-actions class="text-center">
                    <v-btn @click="handleForgotPassword" :disabled="!btn_disabled">{{ $t('auth.reset') }}</v-btn>
                </v-card-actions>
            </v-card>
        </div>
    </front-layout>
</template>

<script>
    import {mapActions, mapMutations} from "vuex";
    import FrontLayout from "../layouts/FrontLayout";
    import Api from "../api/Api";

    export default {
        name: "ForgotPassword",
        components: {FrontLayout},
        data: function () {
            return {
                rules_email: [
                    value => !!value || 'Required.',
                    value => (value && value.length >= 6) || 'Min 6 characters',
                    value => {
                        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                        return pattern.test(value) || 'Invalid e-mail.'
                    },
                ],
                email: '',
                errors: {},
                show_password: false,
                btn_disabled: false,
            }
        },
        methods: {
            async handleForgotPassword() {
                const {email} = this;
                if (this.validate()) {
                    let response = await Api.forgotPassword(email).catch(e => this.errors = e.response.data.errors)
                    if (response.status === 200) {
                        this.errors = {}
                        this.showSnackBar({color: 'success', timeout: 3000, text: 'Password Reset Confirmed'})
                    }
                }
            },
            validate () {
                return this.$refs.form.validate()
            },
            btn_active () {
                this.btn_disabled = this.validate()
            },
            ...mapActions(['signUp']),
            ...mapMutations(['showSnackBar']),
        },
    }
</script>

<style scoped lang="scss">

</style>
